<template>
  <Page>
    <div class="home-page">
      <zui-header />
      <div>
        <img src="https://www.achencybe.com/img/pc/logo.png" alt="" />
        <div class="clause-box">
          <div v-for="(item,idx) in textList" :key="item.title" class="clause-item"
               :class="{'border-bottom':(idx == (textList.length - 1))}">
            <div class="title">{{ $t(item.title) }}</div>
            <p class="content" v-for="p in item.content" :key="p">
              {{ $t(p) }}
            </p>
          </div>

        </div>
        <div class="clause-box">
          <div v-for="item in salesList" :key="item.title" class="clause-item" style="">
            <div class="title">{{ $t(item.title) }}</div>
            <div class="content" v-for="p in item.list" :key="p">
              {{ $t(p) }}
            </div>
            <div class="margin20" v-if="item.list1"></div>
            <div class="content" v-for="p in item.list1" :key="p">
              {{ $t(p) }}
            </div>
          </div>
        </div>
      </div>
      <zui-footer />
    </div>
  </Page>
</template>
<script>
import "../../less/home-page.less";
import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";
import Util from "../../../Util";

export default {
  components: { Page, ZuiHeader, ZuiFooter },
  data() {
    return {
      textList: [
        {
          title: "clause_page.accept_title",
          content: [
            "clause_page.accept_content.item_1"],
        },
        {
          title: "clause_page.copyright_title",
          content: [
            "clause_page.copyright_content.item_1",
          ],
        },
        {
          title: "clause_page.trade_mark_title",
          content: [
            "clause_page.trade_mark_content.item_1",
          ],
        },
        {
          title: "clause_page.statement_title",
          content: [
            "clause_page.statement_content.item_1",
            "clause_page.statement_content.item_2",
          ],
        },
        {
          title: "clause_page.limitation_title",
          content: [
            "clause_page.limitation_content.item_1",
          ],
        },
        {
          title: "clause_page.product_supply_title",
          content: [
            "clause_page.product_supply_content.item_1",
          ],
        },
        {
          title: "clause_page.third_party_links_title",
          content: [
            "clause_page.third_party_links_content.item_1",
          ],
        },
        {
          title: "clause_page.accessing_title",
          content: [
            "clause_page.accessing_content.item_1",
          ],
        },
        {
          title: "clause_page.law_title",
          content: [
            "clause_page.law_content.item_1",
          ],
        },
      ],
      salesList: [
        {
          title: "clause_page.sales_list.return_policy.title",
          list: [
            "clause_page.sales_list.return_policy.success_list.list_1",
            "clause_page.sales_list.return_policy.success_list.list_2",
            "clause_page.sales_list.return_policy.success_list.list_3",
            "clause_page.sales_list.return_policy.success_list.list_4",
          ],
          list1: [
            "clause_page.sales_list.return_policy.failed_list.list_1",
            "clause_page.sales_list.return_policy.failed_list.list_2",
            "clause_page.sales_list.return_policy.failed_list.list_3",
            "clause_page.sales_list.return_policy.failed_list.list_4",
            "clause_page.sales_list.return_policy.failed_list.list_5",
            "clause_page.sales_list.return_policy.failed_list.list_6",
            "clause_page.sales_list.return_policy.failed_list.list_7",
            "clause_page.sales_list.return_policy.failed_list.list_8",
            "clause_page.sales_list.return_policy.failed_list.list_9",
          ]
        },
        {
          title: "clause_page.sales_list.return_guide.title",
          list: [
            "clause_page.sales_list.return_guide.guide_list.list_1",
            "clause_page.sales_list.return_guide.guide_list.list_2",
            "clause_page.sales_list.return_guide.guide_list.list_3",
            "clause_page.sales_list.return_guide.guide_list.list_4",
            "clause_page.sales_list.return_guide.guide_list.list_5",
            "clause_page.sales_list.return_guide.guide_list.list_6",
            "clause_page.sales_list.return_guide.guide_list.list_7",
          ]
        },
        {
          title: "clause_page.sales_list.return_time.title",
          list: [
            "clause_page.sales_list.return_time.list.list_1",
            "clause_page.sales_list.return_time.list.list_2",
            "clause_page.sales_list.return_time.list.list_3",
            "clause_page.sales_list.return_time.list.list_4",
            "clause_page.sales_list.return_time.list.list_5",
          ]
        },
        {
          title: "clause_page.sales_list.return_freight.title",
          list: [
            "clause_page.sales_list.return_freight.list.list_1"
          ]
        },
        {
          title: "clause_page.sales_list.change_policy.title",
          list: [
            "clause_page.sales_list.change_policy.success_list.list_1",
            "clause_page.sales_list.change_policy.success_list.list_2",
            "clause_page.sales_list.change_policy.success_list.list_3",
          ],
          list1: [
            "clause_page.sales_list.change_policy.failed_list.list_1",
            "clause_page.sales_list.change_policy.failed_list.list_2",
            "clause_page.sales_list.change_policy.failed_list.list_3",
            "clause_page.sales_list.change_policy.failed_list.list_4",
            "clause_page.sales_list.change_policy.failed_list.list_5",
            "clause_page.sales_list.change_policy.failed_list.list_6",
            "clause_page.sales_list.change_policy.failed_list.list_7",
            "clause_page.sales_list.change_policy.failed_list.list_8",
            "clause_page.sales_list.change_policy.failed_list.list_9",
            "clause_page.sales_list.change_policy.failed_list.list_10",
          ]
        },
        {
          title: "clause_page.sales_list.change_guide.title",
          list: [
            "clause_page.sales_list.change_guide.guide_list.list_1",
            "clause_page.sales_list.change_guide.guide_list.list_2",
          ]
        },
        {
          title: "clause_page.sales_list.change_freight.title",
          list: [
            "clause_page.sales_list.change_freight.list.list_1"
          ]
        }
      ]
    };
  },
  mounted() {
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
img {
  width: 163px;
  margin-top: 160px;
}

.clause-box {
  max-width: 982px;
  margin: 80px auto 0;
  color: #fff;

  .clause-item {
    padding: 20px 0;

    .title {
      text-align: left;
      font-size: 24px;
      margin-bottom: 10px;
    }

    .content {
      text-align: justify;
      margin-bottom: 10px;
      line-height: 1.6;
      font-size: 18px;
      font-weight: 100;
    }

    .margin20 {
      margin-top: 30px;
    }
  }

  .clause-item + .clause-item {
    border-top: 1px solid #fff;
  }
}

.clause-box + .clause-box {
  margin-top: 10px;
  margin-bottom: 80px;
}

.border-bottom {
  border-bottom: 1px solid #fff;
}
</style>