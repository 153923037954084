var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Page", [
    _c(
      "div",
      { staticClass: "home-page" },
      [
        _c("zui-header"),
        _c("div", [
          _c("img", {
            attrs: {
              src: "https://www.achencybe.com/img/pc/logo.png",
              alt: "",
            },
          }),
          _c(
            "div",
            { staticClass: "clause-box" },
            _vm._l(_vm.textList, function (item, idx) {
              return _c(
                "div",
                {
                  key: item.title,
                  staticClass: "clause-item",
                  class: { "border-bottom": idx == _vm.textList.length - 1 },
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t(item.title))),
                  ]),
                  _vm._l(item.content, function (p) {
                    return _c("p", { key: p, staticClass: "content" }, [
                      _vm._v(" " + _vm._s(_vm.$t(p)) + " "),
                    ])
                  }),
                ],
                2
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "clause-box" },
            _vm._l(_vm.salesList, function (item) {
              return _c(
                "div",
                { key: item.title, staticClass: "clause-item" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$t(item.title))),
                  ]),
                  _vm._l(item.list, function (p) {
                    return _c("div", { key: p, staticClass: "content" }, [
                      _vm._v(" " + _vm._s(_vm.$t(p)) + " "),
                    ])
                  }),
                  item.list1
                    ? _c("div", { staticClass: "margin20" })
                    : _vm._e(),
                  _vm._l(item.list1, function (p) {
                    return _c("div", { key: p, staticClass: "content" }, [
                      _vm._v(" " + _vm._s(_vm.$t(p)) + " "),
                    ])
                  }),
                ],
                2
              )
            }),
            0
          ),
        ]),
        _c("zui-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }